<template>
  <div class="square-container" style="overflow: hidden">
    <div class="nouser" v-if="room.hub_sid && query &&(!user.name || user.name == '') ">
      <div class="bg">
        <img :src="room.pic" style="width: 100%;height: 100%;">
      </div>
      <div class="login-container" v-if="!user.name && !user.uid">
        <div class="login-right">
          <div class="header">
            <router-link to="home">
              <img v-lazy="logo2" alt="" />
            </router-link>
            <h5>短信验证码登陆</h5>
          </div>
          <el-form :model="form" :rules="rules" :show-message="true" :validate-on-rule-change="false" ref="form" v-loading="loading">
            <div class="form">
              <div class="input-box">
                <el-form-item prop="mobile">
                  <el-input v-model="form.mobile" placeholder="输入手机号" class="input" maxlength="15" show-word-limit></el-input>
                </el-form-item>
              </div>
              <div class="input-box">
                <div class="code-box">
                  <el-form-item prop="code" style="width: 50%;">
                    <el-input v-model="form.code" placeholder="短信验证码" class="input code-number" maxlength="6" show-word-limit @blur="codeBlur" @focus="codeFocus"></el-input>
                  </el-form-item>
                  <div v-show="showCode" class="send-code" @click.prevent="getShortMessageCode()" :disabled="!showCode">发送验证码</div>
                  <span v-show="!showCode" class="send-code count">{{ count }} s</span>
                </div>
              </div>
              <div class="form-tips">新用户登陆即自动注册，表示已同意 <router-link to="/serviceAgreement" target="_blank">《用户协议》</router-link>与<router-link to="/privacyAgreement" target="_blank">《隐私协议》</router-link>
              </div>
              <button class="login-btn" @click="loginBtn" v-preventReClick>登录</button>
            </div>
          </el-form>
        </div>
      </div>
      <div class="login-container user-container" v-if="!user.name && user.uid">
        <div class="login-right user-info">
          <div class="header">
            <router-link to="home">
              <img v-lazy="logo2" alt="" />
            </router-link>
          </div>
          <div class="avatar-right">
            <div class="nick-name">
              <span>昵称</span>
              <el-input v-model="userform.name" placeholder="输入昵称" class="nick-txt" maxlength="15"></el-input>
              <!-- <input type="text" :value="user.name" class="nick-txt"> -->
            </div>
            <div class="sex">
              <span>性别</span>
              <div class="sex-both">
                <div class="sex-man" :class="userform.gender==1?'active':''" @click="userform.gender=1">男</div>
                <div class="sex-man sex-women" :class="userform.gender==2?'active':''" @click="userform.gender=2">女</div>
              </div>
            </div>
            <div class="img">
              <img v-lazy="sex_1" v-if="userform.gender==1">
              <img v-lazy="sex_2" v-if="userform.gender==2">
            </div>
            <button class="login-btn" @click="updateUser">确定</button>
          </div>
        </div>
      </div>
    </div>
    <iframe :src="url" style="width: 100%; height: 100%;border: none;" allow="microphone; camera; vr; speaker;display-capture;fullscreen;clipboard-read; clipboard-write" v-if="url"></iframe>
  </div>
</template>
<script>
import { login, getCode } from '@/api/api'
import { getToken } from '@/utils/auth'
import { getRoomDetail, updateUser, getUserDetail } from '@/api/api'
import { deepClone } from '@/utils'
export default {
  name: "metaScene",
  methods: {},
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(rule.message))
      } else {
        callback()
      }
    }
    return {
      sid: '',
      room: {},
      token: getToken(),
      loading: false,
      sex_1: require("../assets/images/sex_1.png"),
      sex_2: require("../assets/images/sex_2.png"),
      logo2: require("../assets/images/logo2.png"),
      wxLogin: require("../assets/images/wx-login.png"),
      url: '',
      timer: 0,
      count: 0,
      query:false,
      errorCode: false,
      showCode: true,
      reg: /^1[0-9]{10}$/,
      user: {},
      userform:{},
      form: {
        mobile: '',
        code: ''
      },
      rules: {
        mobile: [
          { validator: validateRequire, message: '请输入手机号/账号', trigger: 'blur' }
        ],
        code: [
          { validator: validateRequire, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { validator: validateRequire, message: '请输入密码', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    window.addEventListener('message', this.iframeEvent)
  },
  mounted() {
    this.iframe = this.$refs.iframe
    this.$nextTick().then(res => {
      // console.log(this.$route.params.id)
      const query = this.$route.query
      this.sid = query.sid ? query.sid : ''
      if (!this.sid) {
        this.$router.push({ path: "/square" });
      }
      this.getRoomDetail()
      // this.embed_token = query.embed_token ? query.embed_token : ''
      // top.location.replace(`https://hubs.mozilla.com/QKDFUTF`);
      // top.location.replace(`https://app.metaio.cc/${this.$route.params.id}`);
      // this.url =`https://app.metaio.cc/${this.$route.params.id}`
    })
  },
  methods: {
    iframeEvent(event) {
      console.log(event.data)
      if (event.data.type === 'leave') {
        this.$router.push({ path: '/square' })
      }
      if (event.data.sid) {
        this.$router.push({ path: `/join/${event.data.sid}?uid=1` })
      }
    },
    codeBlur() {
      if (!this.form.code) this.errorCode = true
    },
    codeFocus() {
      this.errorCode = false
    },
    getShortMessageCode() {
      if (!this.form.mobile) {
        // this.errorPhone = true;
        // this.phoneTips = '手机号不可为空';
        this.$message.error('手机号不可为空')
        return;
      }
      if (!this.reg.test(this.form.mobile)) {
        // this.errorPhone = true;
        // this.phoneTips = '手机号错误，请重新输入';
        this.$message.error('手机号错误，请重新输入')
        return;
      }
      // 验证码倒计时
      if (!this.timer) {
        getCode({ mobile: this.form.mobile }).then(res => {
          if (res.code == 200) {
            this.count = 60;
            this.showCode = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    updateUser() {
      if (!this.userform.name) {
        return this.$message.error('请输入昵称')
      }
      if (!this.userform.gender) {
        return this.$message.error('请选择性别')
      }
      if (this.userform.gender == 1) {
         this.userform.avatar = 'https://cdn.metaio.cc/files/447944eb-6373-419c-9f33-0176c0add09d'
         this.userform.avatar_id = '1275036378605814073'
         this.userform.avatar_sid = 'L68uUT4'
      }
      if (this.userform.gender == 2) {
         this.userform.avatar = 'https://app.metaio.cc/files/c68811d5-4191-468f-a212-659028529218'
         this.userform.avatar_id = '1275036058857242933'
         this.userform.avatar_sid = 'L0eVlS0'
      }
      // this.user[type] = data
      updateUser(this.userform).then(res => {
        if (res.code == 200) {
          this.getRoomDetail()
          this.$store.dispatch('user/getInfo')
        }
      })
    },
    getRoomDetail(){
      getRoomDetail({ sid: this.sid }).then(res => {
        if (res.code == 200) {
          this.room = res.data
          if (getToken()) {
            this.getUserDetail()
          } else {
            this.query = true
          }
        } else {
          this.$router.push({ path: "/square" });
        }
      })
    },
    getUserDetail() {
      console.log(this.token)
      getUserDetail().then(res => {
        this.query = true
        if (res.code == 200) {
          this.user = res.data
          this.userform = deepClone(res.data)
          if (res.data.name) {
            this.url = `https://app.metaio.cc/${this.room.hub_sid}?embed_token=${this.room.embed_token}&token=${this.token}&title=${this.room.title}&pic=${this.room.pic}&introduction=${this.room.introduction}`
          }
        }
      })
    },
    loginBtn() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/mobilelogin', this.form).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.token = res.data.token
              console.log(this.token)
              this.getUserDetail()
            } else {
              this.$message.error(res.msg)
            }
          }).catch(_ => {
            this.loading = false
          })
        }
      })
    }
  },
  destroyed() {
    // 在组件生命周期结束的时候销毁。
    // window.removeEventListener("message", this.onMessage);
  },
}

</script>
<style scoped lang="scss">
@import '../assets/css/login.scss';

::v-deep .el-input__inner {
  border: none;
  height: 65px;
}

::v-deep .input .el-input__inner {
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0.25rem 0.25rem 0.4375rem -0.1875rem rgb(27 27 27 / 25%);
  border-radius: 0.375rem;
}

::v-deep .el-form-item {
  margin: 0;
}

.metaApp-page {
  color: #000;
  font-size: 64px;
  text-align: center;
}

.bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #4a5a75;
}

.login-container {
  .login-right {
    width: 500px;
    height: 700px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0 60px;
    background: #fff;
    text-align: center;
    .header{
      margin-bottom: 50px;
    }
    .form{
      margin:0;
      max-width:100%;
    }
  }
}
.user-container {
    .user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      .img{
        margin-left: -50px;
        img{
          width: 230px;
        }
      }
      .avatar-right {
        text-align: center;
        .login-btn {
          margin-top: 40px;
          width: 394px;
          height: 59px;
          /*no*/
          text-align: center;
          line-height: 59px;
          /*no*/
          color: #ffffff;
          background: rgba(0, 0, 0);
          box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 4px;
          outline: none;
          border: none;
          cursor: pointer;
        }
        span {
          color: rgba(0, 0, 0, 0.5);
          font-size: 32px;
        }
        .nick-name {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span{
            margin-right: 40px;
            width: 100px;
          }
          .nick-txt {
            font-size: 56px;
            outline: none;
            border: none;
            width: 400px;
          }
        }
        .sex {
          margin-top: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span{
            margin-right: 40px;
            width: 100px;
          }
          .sex-both {
            display: flex;
            align-items: center;
            width: 400px;
            > div {
              font-size: 40px;
              cursor: pointer;
            }
            .sex-man {
              width: 100px;
              height: 50px;
              text-align: center;
              line-height: 50px;
              color: #000;
              &.active {
                background: #000000;
                border-radius: 12px;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
</style>
